import angular from 'angular';

import dossierKeys from '@skryv/bundle/customizations/constants/dossierDefinitionKeys';

import template from './VrfDossier.html';

const namespace = 'vrf/components/dossier/VrfDossier';

angular
  .module(namespace, [])
  .component('vrfDossier', {
    template,
    bindings: {
      apiDossier: '<',
      dosdef: '<',
      procdefs: '<',
      processes: '<',
      authorizations: '<'
    },
    controller: function () {
      this.dossierKeys = dossierKeys;
    }
  })
  .name;

export default namespace;