import angular from 'angular';

import template from './ExpertiseTeeltInformatie.html';
import './ExpertiseTeeltInformatie.scss';

const namespace = 'vrf/components/expertise/ExpertiseTeeltInformatie';

angular
  .module(namespace, [])
  .factory('vrfExpertiseTeeltInformatie', function() {
    'ngInject';

    return {
      template: template,
      link: linkExpertiseTeeltInformatie
    };

    function linkExpertiseTeeltInformatie(scope, input, manipulator) {
      let va_code = () => manipulator.computedExpressions.va_cropCode;
      let va_prijs_per_ha = () => manipulator.computedExpressions.va_prijs_per_ha;
      scope.hasTeeltInformationAvailable = () => {
        return (va_code() && va_prijs_per_ha()) || false;
      };

      scope.regularColumns = [{
        value: () => va_code(),
        header: 'VA code',
        label: 'VA code',
        width: '50%'
      },{
        value: () => va_prijs_per_ha(),
        header: 'Bedrag per ha',
        label: 'Bedrag per hectare in de periode van de ramp',
        width: '50%'
      }
      ];

      scope.shouldDisplayAsTable = (value) => {
        return value && value === Object(value);
      };
    }
  });

export default namespace;