import skryv from '@skryv/core-ng1';
import { theme, translation, navigation, form, search } from '@skryv/core-ng1';

import vrfCustomizations from './customizations';

import nl_BE from './translations/nl_BE.po';
import './translations/template.html';
import logo from './theme/assets/images/logo.png';

theme
  .logo(logo, 'VRF');

translation
  .add('nl_BE', nl_BE)
  .locale('nl_BE');

navigation
  .page('dashboard', {
    extend: true,
    views: {
      actions: 'vrfDashboardActions'
    }
  })
  .page('dashboardDossiers', {
    extend: true,
    views: {
      actions: 'vrfDashboardActions'
    }
  })
  .page('dossier', {
    extend: true,
    views: {
      content: 'vrfDossier'
    }
  })
  .page('document', {
    extend: 'document',
    views: {
      summary: 'vrfDocumentSummary',
    }
  })
  .page('subdocument', {
    extend: 'subdocument',
    views: {
      summary: 'vrfDocumentSummary',
    }
  })
  .page('task', {
    extend: 'task',
    views: {
      summary: 'vrfDocumentSummary',
    }
  })
  .page('subtask', {
    extend: 'subtask',
    views: {
      summary: 'vrfDocumentSummary',
    }
  })
  .tab('search','tasks', {
    extend: true,
    authorizationKey: 'SEARCH_TASKS'
  })
  .tab('search','documents', {
    extend: true,
    authorizationKey: 'SEARCH_DOCUMENTS'
  })
  .setTabsForGroup('search',['dossiers','tasks', 'historicTasks', 'documents']);

search
  .defaultForDashboardMyTasks({
    name: 'Default view',
    searchTerm: '',
    columns: [
      'task.urgency',
      'dossier.label',
      'task.name',
      'task.created',
      'task.due'
    ],
    filters: [],
    sort: {
      criterion: 'task.created',
      desc: false
    }
  });

form
  .addCustomComponentName('vrfExpertiseTeeltInformatie', 'vrfExpertiseTeeltInformatie' )
  .addCustomComponentName('vrfPerceel', 'vrfPerceel' )
  .addCustomComponentName('vrfDisasterReferenceList', 'skrReferenceListInput' )
  .addCustomComponentName('vrfPersoon', 'vrfPersoon' )
  .addCustomComponentName('vrfNietPerceel', 'vrfNietPerceel' );

skryv
  .customize(vrfCustomizations)
  .customize(theme)
  .customize(translation)
  .customize(navigation)
  .customize(search)
  .customize(form)
  .initialize(document.getElementById('root'));
