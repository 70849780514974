import angular from 'angular';
import moment from 'moment';
import find from 'lodash/find';
import get from 'lodash/get';

import { fetchDocumentById } from '@skryv/core-ng1/core/store/actions/documents';
import { selectDocument } from '@skryv/core-ng1/core/store/selectors/documents';
import { fetchLVPerceelReflist } from '@skryv/bundle/customizations/actions/vrfLV';

import template from './vrfPerceel.html';
import './vrfPerceel.scss';

const namespace = 'vrf/components/document/vrfPerceel';

angular
  .module(namespace, [])
  .factory('vrfPerceel', function($timeout,$q,$ngRedux) {
    'ngInject';

    return {
      template: template,
      link: linkPerceel
    };

    function linkPerceel(scope, input, manipulator) {
      function findComponent(list, name) {
        return find(list, (component) => {
          const options = component.inputOptions;
          return options.name === name;
        });
      }

      // We grab the nested components from the current fieldset,
      // and the nested manipulators.

      const components = scope.component.components;
      const {
        perceel,
        hectare,
        teelt,
        raming_schade_percentage
      } = scope.manipulator.propertyManipulators;

      // Link the components and manipulators.
      scope.perceel = {
        component: findComponent(components, 'perceel'),
        manipulator: perceel
      };

      scope.hectare = {
        component: findComponent(components, 'hectare'),
        manipulator: hectare
      };

      scope.teelt = {
        component: findComponent(components, 'teelt'),
        manipulator: teelt
      };

      scope.raming_schade_percentage = {
        component: findComponent(components, 'raming_schade_percentage'),
        manipulator: raming_schade_percentage
      };

      // perceel referencelist (we don't do anything with the baseUrl or reflistName, but the docmod will pass this to us)
      let fetchReferencelist = (documentId, kboNumberOfFarmer, year, disasterCode) => (baseUrl,reflistName,cb) => {
        if(!documentId || !kboNumberOfFarmer || !year || !disasterCode) {
          console.error('No documentId, kbo number or code or year of disaster present; could not fetch L&V perceel referencelist');
          return $q.when([]);
        }
        return resolveDossierIdForDocument(documentId).then((dossierId) => {
          return $ngRedux.dispatch(fetchLVPerceelReflist(dossierId, kboNumberOfFarmer, year, disasterCode))
            .then((state) => {
              return cb(undefined,get(state,['api','response','data']));
            }).catch((error) => {
              cb(error);
            });
        });
      };

      let resolveDossierIdForDocument = (documentId) => {
        // usually the document will be saved in the redux state, so we can use find it there
        let document = selectDocument($ngRedux.getState(),documentId);
        let dossierId = get(find(get(document,'links'), { resourceType: 'DOSSIER' }),'resourceId');
        if(dossierId) return $q.when(dossierId);

        // otherwise, we need to fetch the document from the backend
        return $ngRedux.dispatch(fetchDocumentById(documentId))
          .then((state) => {
            let lookupBackend = selectDocument(state, documentId);
            let dossierId = get(find(get(lookupBackend,'links'), { resourceType: 'DOSSIER' }),'resourceId');
            return $q.when(dossierId);
          });
      };

      let kboNumberOfFarmer = manipulator.computedExpressions.aanvrager_id;
      let year = moment(manipulator.computedExpressions.ramp_startdatum,'DD-MM-YYYY').year();
      let disasterCode = manipulator.computedExpressions.ramp_code;
      let documentId = scope.document;
      scope.perceel.manipulator.loadReferencelistWithSpecificRequest(fetchReferencelist(documentId, kboNumberOfFarmer, year, disasterCode));

      // if perceel field is selected, enrich other fields appropriately (only if they do not have a value yet to avoid changing manually overwritten values)
      scope.perceel.manipulator.state.onSetMatch((match) => {
        if(!scope.hectare.manipulator.value) {
          scope.hectare.manipulator.value = match.declaredArea;
        }
        if(!scope.teelt.manipulator.state.currentKey()) {
          scope.teelt.manipulator.state.becomeKey(match.cropName);   // MATCH ON code not on NAME cropCode": "8410",
        }
      });

      // if the perceel field is emptied, also empty the related fields (if they have a value)
      scope.perceel.manipulator.state.onClear(() => {
        if(scope.hectare.manipulator.value) {
          scope.hectare.manipulator.clear();
        }
        if(scope.teelt.manipulator.state.currentKey()) {
          scope.teelt.manipulator.inputQuery('');
        }
      });
    }
  });

export default namespace;