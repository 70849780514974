export default {
  AANVRAGER: 'aanvrager',
  DOSSIERBEHANDELAAR: 'dossierbehandelaar',
  DIENSTHOOFD: 'diensthoofd',
  CONTENTADMIN: 'contentadmin',
  ORAFIN: 'orafin',
  EXPERT_VOERTUIG: 'expert_voertuig',
  EXPERT_LANDBOUW: 'expert_landbouw',
  EXPERT_GEBOUW: 'expert_gebouw',
  EXPERT_CONTACT_GEBOUW: 'expert_contact_gebouw',
  EXPERT_CONTACT_VOERTUIG: 'expert_contact_voertuig',
  EXPERT_CONTACT_LANDBOUW: 'expert_contact_landbouw',
  EXPERT: 'expert',
  EXPERTISE_VERDELER: 'expertise_verdeler',
  ADMIN: 'admin'
};
