import angular from 'angular';
import moment from 'moment';
import get from 'lodash/get';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import immutable from 'seamless-immutable';

import { fetchAuthorizationsForUser } from '@skryv/core-ng1/core/store/actions/authorizations';
import { fetchDossierComments } from '@skryv/core-ng1/core/store/actions/dossiercomments';
import { updateDossier } from '@skryv/core-ng1/core/store/actions/dossiers';
import { selectDossierDetails, selectMilestonesForDossier } from '@skryv/core-ng1/core/store/selectors/dossiers';
import { selectPinnedDossierComments } from '@skryv/core-ng1/core/store/selectors/dossiercomments';
import { fetchDossierDetails, fetchDossierMilestones } from '@skryv/core-ng1/core/store/actions/dossiers';
import { fetchUsersForRole } from '@skryv/core-ng1/core/store/actions/users';
import { checkAuthorizationForUiComponent } from '@skryv/core-ng1/core/store/actions/authorizations';
import { selectUsersByDossierAndRole } from '@skryv/core-ng1/core/store/selectors/users';

import roleKeys from '@skryv/bundle/customizations/constants/roleKeys';
import documentDefinitionKeys from '@skryv/bundle/customizations/constants/documentDefinitionKeys';

import template from './VrfDossierErkenning.html';
import './VrfDossierErkenning.scss';

const namespace = 'vrf/components/dossier/VrfDossierErkenning';

angular
  .module(namespace, [])
  .component('vrfDossierErkenning', {
    template,
    bindings: {
      apiDossier: '<',
      dosdef: '<'
    },
    controller: function ($ngRedux, $filter, $state, $timeout, userService, notifications) {
      'ngInject';
      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchUsersForRole, fetchDossierComments, updateDossier, fetchDossierMilestones, fetchDossierDetails, checkAuthorizationForUiComponent, fetchAuthorizationsForUser })(this);
      this.$onDestroy = disconnect;
      this.$onInit = () => {
        this.processStepsOpen = false;
        this.dossierId = get(this.apiDossier, ['id']);

        this.loadingInitialDetails = true;
        this.fetchDossierDetails(this.dossierId).then(() => this.loadingInitialDetails = false);
        this.fetchDossierComments(this.dossierId);
        this.fetchDossierMilestones(this.dossierId);
        this.fetchUsersForRole(this.dossierId, roleKeys.DOSSIERBEHANDELAAR);

        this.checkAuthorizationForUiComponent(userService.getCurrentUserSub(), 'EDIT_DOSSIER_METADATA')
          .then(({ api }) => {
            this.isAuthorizedForEditingMetadata = get(api, 'response.data.authorized');
          });

        // three seconds after initialisation, we fetch everything a second time again; this to be sure that we have the most recent version
        $timeout(this.refreshDossier, 3000);
      };

      this.loadDossierInformation = () => {
        this.rampInfo = rampInfo();
        this.dossierInfo = getDossierInfo();
      };

      this.refreshDossier = () => {
        // since we do not use the apiDossier currently, we do not refetch it
        this.fetchDossierDetails(this.dossierId);
        this.fetchDossierMilestones(this.dossierId);
        this.fetchDossierComments(this.dossierId);
      };

      // important milestones
      this.goToHistoryPage = () => {
        $state.go('dossierHistory', { dossierId: this.dossierId }, { reload: false });
      };

      this.userDescription = (user) => {
        return userService.userDescription(user);
      };

      this.toggleEditDossierManager = () => {
        this.isEditingDossierManager = !this.isEditingDossierManager;
      };

      this.saveDossier = () => {
        // only the dossierManager is editable from the overview
        this.updateDossier({ id: this.dossierId, dossierManager: get(this.dossierManager, 'sub', null) })
          .then(() => this.fetchDossierDetails(this.dossierId))
          .then(() => {
            notifications.info('Updated the dossier!');
            this.isEditingDossierManager = false;
          }).catch(() => {
            notifications.error('Could not save the dossier');
          });
      };

      function mapStateToThis(state) {
        let newDossier = selectDossierDetails(state, $ctrl.dossierId);
        if (newDossier) {
          const users =  immutable.asMutable(selectUsersByDossierAndRole(state, $ctrl.dossierId, roleKeys.DOSSIERBEHANDELAAR), { deep: true })
          const dossierManagerSub = newDossier.dossier.dossierManager;
          const dossierManager = find(users, user => user.sub == dossierManagerSub);

          $ctrl.dossierManager = dossierManager;

          $ctrl.dossierDetails = newDossier;
          $ctrl.loadDossierInformation();
        }

        const historicTasks = $filter('orderBy')($filter('filter')(get($ctrl.dossierDetails, 'task'), { 'active': false }), 'ended', true);
        const processInfo = historicTasks ? historicTasks.map((task) => {
          return {
            id: task.id,
            name: task.name,
            task: task,
            status: 'finished',
            action: (task) => $state.go('task', { taskId: task.id }),
            hasAction: true,
            canExecute: true,
            actionLabel: 'Open'
          };
        }) : [];
        const pinnedComments = immutable.asMutable(selectPinnedDossierComments(state, $ctrl.dossierId), { deep: true });


        // milestones *CONFIG*
        const importantMilestones = [
          'erkenning_toegevoegd', 'erkenning_gewijzigd'];
        $ctrl.allMilestones = selectMilestonesForDossier(state, $ctrl.dossierId);
        $ctrl.milestonesToShow = $filter('filter')($ctrl.allMilestones, (value) => importantMilestones.includes(value.key));
        $ctrl.pinnedComments = immutable.asMutable(selectPinnedDossierComments(state, $ctrl.dossierId), { deep: true });

        return {
          dossierDetails: selectDossierDetails(state, $ctrl.dossierId),
          activeTasks: $filter('orderBy')($filter('filter')(get($ctrl.dossierDetails, 'task'), { 'active': true }), 'created', true),
          processInfo,
          pinnedComments,
          users: immutable.asMutable(selectUsersByDossierAndRole(state, $ctrl.dossierId, roleKeys.DOSSIERBEHANDELAAR), { deep: true })
        };
      }

      // docs
      const getErkenningDoc = () => {
        return $filter('orderBy')($filter('filter')(get(this.dossierDetails, ['document']), { definition: { key: documentDefinitionKeys.ERKENNING_DOCUMENT } }), 'updatedAt', true)[0];
      };

      // dossier information
      const rampInfo = () => {
        const erkenningDoc = getErkenningDoc();
        const erkenningInfoPath = [documentDefinitionKeys.ERKENNING_DOCUMENT];

        return erkenningDoc ? { label: 'Beschrijving ramp', value: get(erkenningDoc, [...erkenningInfoPath, 'beschrijving_van_de_schade', 'value']) || 'n/a' } : false;
      };
      const getDossierInfo = () => {
        const erkenningDoc = getErkenningDoc();
        const erkenningInfoPath = [documentDefinitionKeys.ERKENNING_DOCUMENT];

        const fieldsFromErkenning = erkenningDoc ? [
          { label: 'Startdatum', value: datePretty(get(erkenningDoc, [...erkenningInfoPath, 'startdatum'])) || 'n/a' },
          { label: 'Einddatum', value: datePretty(get(erkenningDoc, [...erkenningInfoPath, 'einddatum'])) || 'n/a' },
          { label: 'Datum publicatie staatsblad', value: datePretty(get(erkenningDoc, [...erkenningInfoPath, 'datum_publicatie_staatsblad'])) || 'n/a' },
          { label: 'Einddatum aanvraagperiode', value: get(erkenningDoc, [...erkenningInfoPath, 'einddatum_aanvraagperiode']) || 'n/a' },
          { label: 'Gemeentes', value: gemeentesPretty(get(erkenningDoc, [...erkenningInfoPath, 'postcodes', 'elements'])) || 'n/a' }
        ] : [];

        return fieldsFromErkenning;
      };

      // helper functions
      const datePretty =  (date) => {
        if (!date || !moment(date).isValid()) return undefined;
        return moment(date).format('DD-MM-YYYY');
      };
      const gemeentesPretty =  (postcodes) => {
        if (!postcodes) return undefined;
        let allGemeentes = uniq(postcodes.map((item) => get(item, ['postcode'], ['label'], 'n/a')));
        remove(allGemeentes, (cat) => cat === 'n/a');
        return allGemeentes ? allGemeentes.join(', ') : false;
      };

    }

  });

export { template };
export default namespace;