import angular from 'angular';
import moment from 'moment';
import get from 'lodash/get';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import immutable from 'seamless-immutable';

import { fetchAuthorizationsForUser } from '@skryv/core-ng1/core/store/actions/authorizations';
import { fetchDossierComments } from '@skryv/core-ng1/core/store/actions/dossiercomments';
import { updateDossier } from '@skryv/core-ng1/core/store/actions/dossiers';
import { selectDossierDetails, selectMilestonesForDossier } from '@skryv/core-ng1/core/store/selectors/dossiers';
import { selectPinnedDossierComments } from '@skryv/core-ng1/core/store/selectors/dossiercomments';
import { fetchDossierDetails, fetchDossierMilestones } from '@skryv/core-ng1/core/store/actions/dossiers';
import { fetchUsersForRole } from '@skryv/core-ng1/core/store/actions/users';
import { checkAuthorizationForUiComponent } from '@skryv/core-ng1/core/store/actions/authorizations';
import { selectUsersByDossierAndRole } from '@skryv/core-ng1/core/store/selectors/users';

import roleKeys from '@skryv/bundle/customizations/constants/roleKeys';
import documentDefinitionKeys from '@skryv/bundle/customizations/constants/documentDefinitionKeys';
import taskDefinitionKeys from '@skryv/bundle/customizations/constants/taskDefinitionKeys';

import template from './VrfDossierAanvraag.html';
import './VrfDossierAanvraag.scss';

const namespace = 'vrf/components/dossier/VrfDossierAanvraag';

angular
  .module(namespace, [])
  .component('vrfDossierAanvraag', {
    template,
    bindings: {
      apiDossier: '<'
    },
    controller: function ($ngRedux, $filter, $state, $timeout, userService, notifications) {
      'ngInject';
      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchUsersForRole, fetchDossierComments, updateDossier, fetchDossierMilestones, fetchDossierDetails, checkAuthorizationForUiComponent, fetchAuthorizationsForUser })(this);
      this.$onDestroy = disconnect;
      this.$onInit = () => {
        this.processStepsOpen = false;
        this.dossierId = get(this.apiDossier, ['id']);

        this.loadingInitialDetails = true;
        this.fetchDossierDetails(this.dossierId).then(() => this.loadingInitialDetails = false);
        this.fetchDossierComments(this.dossierId);
        this.fetchDossierMilestones(this.dossierId);
        this.fetchUsersForRole(this.dossierId, roleKeys.DOSSIERBEHANDELAAR);

        this.checkAuthorizationForUiComponent(userService.getCurrentUserSub(), 'EDIT_DOSSIER_METADATA')
          .then(({ api }) => {
            this.isAuthorizedForEditingMetadata = get(api, 'response.data.authorized');
          });

        // three seconds after initialisation, we fetch everything a second time again; this to be sure that we have the most recent version
        $timeout(this.refreshDossier, 3000);
      };

      this.loadDossierInformation = () => {
        this.rampInfo = rampInfo();
        this.dossierInfo = getDossierInformation();
      };

      this.refreshDossier = () => {
        // since we do not use the apiDossier currently, we do not refetch it
        this.fetchDossierDetails(this.dossierId);
        this.fetchDossierMilestones(this.dossierId);
        this.fetchDossierComments(this.dossierId);
      };

      // important milestones
      this.goToHistoryPage = () => {
        $state.go('dossierHistory', { dossierId: this.dossierId }, { reload: false });
      };

      this.userDescription = (user) => {
        return userService.userDescription(user);
      };

      this.toggleEditDossierManager = () => {
        this.isEditingDossierManager = !this.isEditingDossierManager;
      };

      this.saveDossier = () => {
        // only the dossierManager is editable from the overview
        const updatedDossier = Object.assign({}, this.apiDossier, { dossierManager: get(this.dossierManager, 'sub', null) });
        this.updateDossier(updatedDossier)
          .then(() => this.fetchDossierDetails(this.dossierId))
          .then(() => {
            notifications.info('Updated the dossier!');
            this.isEditingDossierManager = false;
          }).catch(() => {
            notifications.error('Could not save the dossier');
          });
      };

      function mapStateToThis(state) {
        let newDossier = selectDossierDetails(state, $ctrl.dossierId);
        if (newDossier) {
          const users =  immutable.asMutable(selectUsersByDossierAndRole(state, $ctrl.dossierId, roleKeys.DOSSIERBEHANDELAAR), { deep: true })
          const dossierManagerSub = newDossier.dossier.dossierManager;
          const dossierManager = find(users, user => user.sub == dossierManagerSub);

          $ctrl.dossierManager = dossierManager;

          $ctrl.dossierDetails = newDossier;
          $ctrl.loadDossierInformation();
        }

        const historicTasks = $filter('orderBy')($filter('filter')(get($ctrl.dossierDetails, 'task'), { 'active': false }), 'ended', true);
        const processInfo = historicTasks ? historicTasks.map((task) => {
          return {
            id: task.id,
            name: task.name,
            task: task,
            status: 'finished',
            action: (task) => $state.go('task', { taskId: task.id }),
            hasAction: true,
            canExecute: true,
            actionLabel: 'Open'
          };
        }) : [];
        const pinnedComments = immutable.asMutable(selectPinnedDossierComments(state, $ctrl.dossierId), { deep: true });


        // milestones *CONFIG*
        const importantMilestones = [
          'ingediend_via_eloket', 'manueel_opgestart', 'dossier_afgewezen', 'bijkomende_stukken_opgevraagd', 'termijn_reactie_expertise_verlopen', 'reactie_expertise_ontvangen', 'geen_reactie_expertise_ontvangen', 'besluit_opgestuurd', 'betaling_gestart', 'betaling_geimporteerd', 'betaling_uitgevoerd', 'expertiseverslag_opgestuurd', 'reactie_besluit_ontvangen', 'geen_reactie_besluit_ontvangen', 'termijn_reactie_besluit_verlopen', 'herzieningsbesluit_opgestuurd', 'beroep_zonder_actie', 'beroep_gestart', 'herziening_gestart', 'terug_naar_start'];
        $ctrl.allMilestones = selectMilestonesForDossier(state, $ctrl.dossierId);
        $ctrl.milestonesToShow = $filter('filter')($ctrl.allMilestones, (value) => importantMilestones.includes(value.key));
        $ctrl.pinnedComments = immutable.asMutable(selectPinnedDossierComments(state, $ctrl.dossierId), { deep: true });

        return {
          dossierDetails: selectDossierDetails(state, $ctrl.dossierId),
          activeTasks: $filter('orderBy')($filter('filter')(get($ctrl.dossierDetails, 'task'), { 'active': true }), 'created', true),
          processInfo,
          pinnedComments,
          users: immutable.asMutable(selectUsersByDossierAndRole(state, $ctrl.dossierId, roleKeys.DOSSIERBEHANDELAAR), { deep: true })
        };
      }

      // docs
      const getAanvraagDoc = () => {
        return $filter('orderBy')($filter('filter')(get(this.dossierDetails, ['document']), { definition: { key: documentDefinitionKeys.AANVRAAG_DOCUMENT } }), 'updatedAt', true)[0];
      };
      const getExpertiseDoc = () => {
        return $filter('orderBy')($filter('filter')(get(this.dossierDetails, ['document']), { definition: { key: documentDefinitionKeys.EXPERTISE_DOCUMENT } }), 'updatedAt', true)[0];
      };
      const getInhoudelijkeDoc = () => {
        return $filter('orderBy')($filter('filter')(get(this.dossierDetails, ['document']), { definition: { key: documentDefinitionKeys.VALIDEER_AANVRAAG_DOCUMENT } }), 'updatedAt', true)[0];
      };

      const getBevestigExpertiseControleur = () => {
        return $filter('orderBy')($filter('filter')(get(this.dossierDetails, ['document']), { definition: { key: documentDefinitionKeys.BEVESTIG_EXPERTISE_CONTROLEUR } }), 'updatedAt', true)[0];
      };

      // tasks
      const getExpertiseFarmTask = () => {
        return $filter('orderBy')(filter(get(this.dossierDetails, ['task']), { 'taskDefinitionKey': taskDefinitionKeys.expertise_agriculture }))[0];
      };
      const getExpertiseBuildingTask = () => {
        return $filter('orderBy')(filter(get(this.dossierDetails, ['task']), { 'taskDefinitionKey': taskDefinitionKeys.expertise_buildings }))[0];
      };
      const getExpertiseVehicleTask = () => {
        return $filter('orderBy')(filter(get(this.dossierDetails, ['task']), { 'taskDefinitionKey': taskDefinitionKeys.expertise_vehicles }))[0];
      };


      // dossier information
      const rampInfo = () => {
        const aanvraagDoc = getAanvraagDoc();
        const aanvraagInfoPath = [documentDefinitionKeys.AANVRAAG_DOCUMENT];
        return aanvraagDoc ? { label: 'Beschrijving ramp', value: get(aanvraagDoc, [...aanvraagInfoPath, 'omschrijving_ramp']) || get(aanvraagDoc, [...aanvraagInfoPath, 'omschrijving_ramp_eloket']) || 'n/a' } : false;
      };
      const getDossierInformation = () => {
        const aanvraagDoc = getAanvraagDoc();
        const aanvraagInfoPath = [documentDefinitionKeys.AANVRAAG_DOCUMENT];
        const expertiseDoc = getExpertiseDoc();
        const expertiseInfoPath = [documentDefinitionKeys.EXPERTISE_DOCUMENT];
        const expertiseFarmTask = getExpertiseFarmTask();
        const expertiseBuildingTask = getExpertiseBuildingTask();
        const expertiseVehicleTask = getExpertiseVehicleTask();
        const inhoudelijkeDoc = getInhoudelijkeDoc();
        const inhoudelijkeInfoPath = [documentDefinitionKeys.VALIDEER_AANVRAAG_DOCUMENT];
        const bevestigExpertiseControleurDoc = getBevestigExpertiseControleur();
        const bevestigExpertiseControleurPath = [documentDefinitionKeys.BEVESTIG_EXPERTISE_CONTROLEUR];
        const firstTimeRight = get(bevestigExpertiseControleurDoc, [...bevestigExpertiseControleurPath, 'eersteKeerJuist']);

        const fieldsFromAanvraag = aanvraagDoc ? [
          { label: 'Aanvraagdatum', value: aanvraagdatumPretty(get(aanvraagDoc, [...aanvraagInfoPath, 'eloket_aanvraagdatum']), get(aanvraagDoc, [...aanvraagInfoPath, 'papieren_aanvraagdatum'])) || 'n/a' },
          { label: 'Schadelijder', value: natuurlijkpersoonPretty(get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers'])) || rechtspersoonPretty(get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers', 'elements', '0', 'naam_bedrijf']), get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers', 'elements', '0', 'naam_contactpersoon']), get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers', 'elements', '0', 'btwnummer'])) || 'n/a' },
          { label: 'Adres schadelijder', value: addressPretty(get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers', 'elements', '0', 'address'])) || 'n/a' },
          { label: 'Contactgegevens schadelijder', value: contactPretty(get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers', 'elements', '0', 'telefoonnummer']), get(aanvraagDoc, [...aanvraagInfoPath, 'aanvragers', 'elements', '0', 'emailadres'])) || 'n/a' },
          { label: 'Aangevraagde schadetypes', value: categoriesPretty(get(aanvraagDoc, [...aanvraagInfoPath, 'schade', 'elements'])) || 'n/a' }
        ] : [];
        const cumul = inhoudelijkeDoc ? [{ label: 'Gelinkte dossiers', value: get(inhoudelijkeDoc, [...inhoudelijkeInfoPath, 'cumul_dossiers', 'value']) }] : [];
        const type_expertise = inhoudelijkeDoc ? [{ label: 'Type expertise', value: threeTypesExpertisePretty(get(inhoudelijkeDoc, [...inhoudelijkeInfoPath, 'complexiteit_gebouw', 'selectedOption']), get(inhoudelijkeDoc, [...inhoudelijkeInfoPath, 'complexiteit_voertuig', 'selectedOption']), get(inhoudelijkeDoc, [...inhoudelijkeInfoPath, 'complexiteit_landbouw', 'selectedOption'])) }] : [];
        const experts = (expertiseFarmTask || expertiseBuildingTask || expertiseVehicleTask) ? [{ label: 'Expert', value: expertsPretty(expertiseFarmTask, expertiseBuildingTask, expertiseVehicleTask, firstTimeRight) }] : [];
        const fieldsFromExpertise = expertiseDoc ? [
          { label: 'Aangevraagd bedrag', value: euroAmountPretty(get(expertiseDoc, [...expertiseInfoPath, 'totale_aangevraagde_schadevergoeding'])) || 'n/a' },
          { label: 'Finale vergoeding', value: euroAmountPretty(get(expertiseDoc, [...expertiseInfoPath, 'totale_schadevergoeding'])) || 'n/a' }
        ] : [{ label: '', value: '' }];

        return fieldsFromAanvraag.concat(cumul).concat(type_expertise).concat(experts).concat(fieldsFromExpertise);
      };

      // helper functions for dossier information
      const natuurlijkpersoonPretty = (aanvrager) => {
        if (!aanvrager || !get(aanvrager, ['elements', '0', 'rrn_naam', 'naam'])) return;
        return get(aanvrager, ['elements', '0', 'rrn_naam', 'naam'])
          + ' (' + rrnPretty(get(aanvrager, ['elements', '0', 'rrn_naam', 'rijksregisternummer'])) + ')'
          + (get(aanvrager, ['elements', '1', 'rrn_naam', 'naam']) ?
            '\n' + get(aanvrager, ['elements', '1', 'rrn_naam', 'naam'])
            + ' (' + rrnPretty(get(aanvrager, ['elements', '1', 'rrn_naam', 'rijksregisternummer'])) + ')' : '')
          + (get(aanvrager, ['elements']).length > 2 ? '\nen nog ' + (get(aanvrager, ['elements']).length - 2) + ' schadelijder(s)' : '');
      };
      const rrnPretty = (rrn) => {
        if (!rrn) {
          return '';
        }
        if (rrn.length != 11) {
          return rrn;
        }
        return rrn[0] + rrn[1] + '.' + rrn[2] + rrn[3] + '.' + rrn[4] + rrn[5] + '-' + rrn[6] + rrn[7] + rrn[8] + '.' + rrn[9] + rrn[10];
      };
      const rechtspersoonPretty = (naam_bedrijf, naam_contactpersoon, btwnummer) => {
        if (!naam_bedrijf && !naam_contactpersoon && !btwnummer) return;
        return naam_bedrijf + (naam_contactpersoon ? ' - ' + naam_contactpersoon : '') + (btwnummer ? ' (' + btwnummer + ')' : '');
      };
      const addressPretty = (address) => {
        if (!address || !get(address, 'municipality')) return;

        if (get(address, 'boxnumber')) {
          return capitalize(get(address, 'street', '')) + ' ' + get(address, 'housenumber', '') + ', bus ' + get(address, 'boxnumber', '') + '\n'
            + get(address, 'zipcode', '') + ' ' + get(address, 'municipality', '');
        }
        else {
          return capitalize(get(address, 'street', '')) + ' ' + get(address, 'housenumber', '') + '\n'
            + get(address, 'zipcode', '') + ' ' + capitalize(get(address, 'municipality', ''));
        }
      };
      const contactPretty = (telephone, email) => {
        if (!telephone && !email) return;
        if (!telephone) return email;
        if (!email) return telephone;
        return telephone + '\n' + email;
      };
      const capitalize = (string) => {
        if (!string) return;
        let capitalizeWord = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        return string.split(' ')
          .map((word) => capitalizeWord(word))
          .join(' ');
      };
      const aanvraagdatumPretty = (eloket_aanvraagdatum, papieren_aanvraagdatum) => {
        let datePretty = (date) => {
          if (!date || !moment(date).isValid()) return undefined;
          return moment(date).format('D/M/YYYY');
        };

        if (eloket_aanvraagdatum) return datePretty(eloket_aanvraagdatum);
        if (papieren_aanvraagdatum) return datePretty(papieren_aanvraagdatum);
        return undefined;
      };
      const categoriesPretty = (schadeItems) => {
        if (!schadeItems) return;
        let allCategories = uniq(schadeItems.map((item) => get(item, ['schadetype', 'selectedOption'], 'n/a')));
        remove(allCategories, (cat) => cat === 'n/a');
        return allCategories ? allCategories.join(', ').toUpperCase() : false;
      };
      const euroAmountPretty = (bedrag) => {
        if (!bedrag) return;
        return bedrag + '€';
      };
      const threeTypesExpertisePretty = (type_expertise_gebouw, type_expertise_voertuig, type_expertise_landbouw) => {
        if (!type_expertise_gebouw && !type_expertise_voertuig && !type_expertise_landbouw) return;
        return (type_expertise_gebouw ? 'Gebouw: ' + typeExpertisePretty(type_expertise_gebouw) + '\n' : '') +
          (type_expertise_landbouw ? 'Landbouw: ' + typeExpertisePretty(type_expertise_landbouw) + '\n' : '') +
          (type_expertise_voertuig ? 'Voertuig: ' + typeExpertisePretty(type_expertise_voertuig) + '\n' : '');
      };
      const typeExpertisePretty = (type_expertise) => {
        if (!type_expertise) return;
        if (type_expertise == 'geen_plaatsbezoek') return 'Geen plaatsbezoek';
        else if (type_expertise == 'plaatsbezoek') return 'Geen complex dossier, maar plaatsbezoek nodig';
        else if (type_expertise == 'complex') return 'Complex dossier met plaatsbezoek';
        else return type_expertise;
      };

      const translateBoolean = (boolean) => boolean ? 'Ja' : 'Nee'

      const expertsPretty = (farm_task, building_task, vehicle_task, first_time_right) => {
        return (building_task ? 'Gebouw: ' + userService.userDescription(building_task.assigneeUser) + '\n' : '') +
          (farm_task ? 'Landbouw: ' + userService.userDescription(farm_task.assigneeUser) + ' (' + translateBoolean(first_time_right) + ')' + '\n' : '') +
          (vehicle_task ? 'Voertuig: ' + userService.userDescription(vehicle_task.assigneeUser) + '\n' : '');
      };
    }

  });

export { template };
export default namespace;