import angular from 'angular';
import find from 'lodash/find';
import template from './vrfPersoon.html';
import './vrfPersoon.scss';

const namespace = 'vrf/components/document/vrfPersoon';

angular
  .module(namespace, [])
  .factory('vrfPersoon', function() {
    'ngInject';

    return {
      template: template,
      link: linkPersoon
    };

    function linkPersoon(scope) {
      function findComponent(list, name) {
        return find(list, (component) => {
          const options = component.inputOptions;
          return options.name === name;
        });
      }

      // We grab the nested components from the current fieldset,
      // and the nested manipulators.

      const components = scope.component.components;
      const {
        rijksregisternummer,
        naam
      } = scope.manipulator.propertyManipulators;

      // Link the components and manipulators.
      scope.rijksregisternummer = {
        component: findComponent(components, 'rijksregisternummer'),
        manipulator: rijksregisternummer
      };

      scope.naam = {
        component: findComponent(components, 'naam'),
        manipulator: naam
      };

    }
  });

export default namespace;