import angular from 'angular';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { fetchBulkActions } from '@skryv/core-ng1/core/store/actions/tasks';
import { selectBulkActions } from '@skryv/core-ng1/core/store/selectors/bulkActions';

import template from './DashboardActions.html';

const namespace = 'vrf/customizations/components/dashboard/DashboardActions';

angular
  .module(namespace, [])
  .component('vrfDashboardActions', {
    template,
    bindings: {
      'isAuthorizedForNewDossier': '<'
    },
    controller: function($ngRedux, dialogService) {
      'ngInject';

      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, { fetchBulkActions })(this);
      this.$onDestroy = disconnect;

      this.$onInit = () => {
        this.actions = this.buildActionsMenu();
        this.fetchBulkActions();
        this.initialized = true;
      };

      this.buildActionsMenu = (bulkTasks = []) => {
        let actions = [];

        if (this.isAuthorizedForNewDossier) {
          let subActions = [
            {
              key: 'start_1_aanvraag',
              label: 'Aanvraag tot schadevergoeding',
              icon: 'skr-icon-dossier',
              action: function () {
                dialogService.openDialog('New dossier', '<vrf-dossier-new></vrf-dossier-new>');
              }
            },
            {
              key: 'start_2_melding',
              label: 'Melding',
              icon: 'skr-icon-dossier',
              action: function () {
                dialogService.openDialog('New dossier', '<vrf-dossier-new-melding></vrf-dossier-new-melding>');
              }
            },
            {
              key: 'start_2_erkenning',
              label: 'Erkenning',
              icon: 'skr-icon-dossier',
              action: function () {
                dialogService.openDialog('New dossier', '<vrf-dossier-new-erkenning></vrf-dossier-new-erkenning>');
              }
            }
          ];

          actions.push({
            label: 'New dossier',
            isDropdown: true,
            order: 1,
            subActions
          });
        }

        if(isEmpty(bulkTasks)) return actions;

        let subActions = map(
          bulkTasks,
          (task) => {
            return {
              key: task.taskDefinitionKey,
              label: task.label,
              icon: 'skr-icon-task',
              action: () => {
                const data = { task };
                dialogService.openDialog('New bulk dossier', '<skr-dossier-bulk-dialog></skr-dossier-bulk-dialog>', data);
              }
            };
          }
        );

        actions.push({
          label: 'Bulk actions',
          isDropdown: true,
          order: 2,
          subActions
        });
        return actions;
      };

      function mapStateToThis(state) {
        // it's possible that the component hasn't initialized fully at this point.
        // check if the component has initialized fully and if not, just return...
        if (!$ctrl.initialized) return {};
        const bulkTasks = selectBulkActions(state);
        if(bulkTasks && !isEmpty(bulkTasks)) {
          return {
            actions: $ctrl.buildActionsMenu(bulkTasks)
          };
        }
        return {};
      }
    }
  });

export { template };
export default namespace;
