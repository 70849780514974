import { Angular1Plugin } from '@skryv/core-ng1';

import components from './components';

export class VrfCustomizations extends Angular1Plugin {
  constructor() {
    super('vrf', [
      components
    ]);
  }
}

export default new VrfCustomizations();
